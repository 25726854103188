const nameRegex = /^[A-Za-z ]+$/
const StepOneRules = {
  name: [
    { required: true, message: 'Please input name', trigger: 'blur' },
    {
      required: true, pattern: nameRegex, message: 'Input should contain only English letters and space.', trigger: 'blur'
    },
    { min: 2, max: 250, message: 'Length should be 2 to 255', trigger: 'blur' }
  ],
  email: [
    { required: true, message: 'Please input email', trigger: 'blur' },
    { type: 'email', message: 'Please input correct email', trigger: 'blur' }
  ],
  beneficiary_address: [
    { required: true, message: 'Please input beneficiary address', trigger: 'blur' },
    { min: 2, max: 255, message: 'Length should be 2 to 250', trigger: 'blur' }
  ],
  beneficiary_city: [
    { required: true, message: 'Please input beneficiary city', trigger: 'blur' },
    { min: 2, max: 250, message: 'Length should be 2 to 255', trigger: 'blur' }
  ],
  beneficiary_country: [
    { required: true, message: 'Please select beneficiary country', trigger: 'blur' }
  ],
  beneficiary_company_name: [
    { required: true, message: 'Please input beneficiary company name', trigger: 'blur' },
    { min: 2, max: 250, message: 'Length should be 2 to 255', trigger: 'blur' }
  ]
}
const bicSwiftRegex = /^[A-Za-z]{6}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/
const StepTwoRules = {
  currency: [
    { required: true, message: 'Please select currency', trigger: 'blur' }
  ],
  bank_account_holder_name: [
    { required: true, message: 'Please input bank account holder name', trigger: 'blur' },
    { min: 2, max: 20, message: 'Length should be 2 to 20', trigger: 'blur' }
  ],
  bank_country: [
    { required: true, message: 'Please select bank country', trigger: 'blur' }
  ],
  account_number: [
    { required: true, message: 'Please input account number', trigger: 'blur' },
    { pattern: /^\d{8,25}$/, message: 'Please put valid account number', trigger: 'blur' }
  ],
  bic_swift: [
    { required: true, message: 'Please input BIC/SWIFT', trigger: 'blur' },
    {
      required: true, pattern: bicSwiftRegex, message: 'Please put valid Swift/bic', trigger: 'blur'
    }
  ]
}
export { StepOneRules, StepTwoRules }
