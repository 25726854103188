import { render, staticRenderFns } from "./BeneficiaryAddStepOne.vue?vue&type=template&id=c34861de&scoped=true&"
import script from "./BeneficiaryAddStepOne.vue?vue&type=script&lang=js&"
export * from "./BeneficiaryAddStepOne.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c34861de",
  null
  
)

export default component.exports