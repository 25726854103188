<template>
  <div class=" max-w-2xl mx-auto rounded-md p-4">
    <div class="pb-6 border-b">
      <div class=" black95 text-2xl font-500 text-center">基本信息</div>
    </div>
    <a-form-model
    id="beneficiaryAddStepOneForm"
    ref="beneficiaryAddStepOneForm"
    :rules="StepOneRules"
    layout="horizontal"
    :label-col="{ span: 6 }"
    :wrapper-col="{ span: 14 }"
    :model="beneficiaryStore.formData"
    >
    <div class="pb-6 border-b">
      <a-form-model-item prop="first_name" class="mt-4 black95 text-sm font-500" label="收款人名字">
        <a-input v-model="beneficiaryStore.formData.first_name"
        @change="(e)=>{handleInput(e, 'last_name')}"
        class="" size="default" placeholder="" />
      </a-form-model-item>
      <a-form-model-item prop="last_name" class="mt-4 black95 text-sm font-500" label="收款人姓">
        <a-input v-model="beneficiaryStore.formData.last_name"
        @change="(e)=>{handleInput(e, 'last_name')}"
        class="" size="default" placeholder="" />
      </a-form-model-item>
      <a-form-model-item prop="email" class="mt-4 black95 text-sm font-500" label="收款人邮箱">
        <a-input v-model="beneficiaryStore.formData.email"
        @change="(e)=>{handleInput(e, 'email')}"
        class="" size="default" placeholder="" />
      </a-form-model-item>
      <a-form-model-item prop="beneficiary_country" class="mt-4 black95 text-sm font-500" label="收款人国家">
        <a-select
          size="default"
          show-search
          placeholder="Select Country"
          option-filter-prop="children"
          style="width: 100%"
          :filter-option="true"
          :not-found-content="null"
          v-model="beneficiaryStore.formData.beneficiary_country"
          @select="(e)=>{handleSelect(e, 'beneficiary_country')}"
        >
        <a-select-option v-for="item in ccCountryList" :key="item.fullname" :value="item.shortname">
        {{ item.fullname  }}
      </a-select-option>
        </a-select>
      </a-form-model-item>
       <a-form-model-item prop="beneficiary_city" class="mt-4 black95 text-sm font-500" label="城市">
        <a-input v-model="beneficiaryStore.formData.beneficiary_city"
        @change="(e)=>{handleInput(e, 'beneficiary_city')}"
        class="" size="default" placeholder="" />
      </a-form-model-item>
      <a-form-model-item prop="beneficiary_address" class="mt-4 black95 text-sm font-500" label="地址">
        <a-input v-model="beneficiaryStore.formData.beneficiary_address"
        @change="(e)=>{handleInput(e, 'beneficiary_address')}"
        class="" size="default" placeholder="" />
      </a-form-model-item>
      <a-form-model-item prop="beneficiary_company_name" class="mt-4 black95 text-sm font-500" label="公司名字">
        <a-input v-model="beneficiaryStore.formData.beneficiary_company_name"
        @change="(e)=>{handleInput(e, 'beneficiary_company_name')}"
        class="" size="default" placeholder="" />
      </a-form-model-item>
      <a-form-model-item prop="beneficiary_type" class="mt-4 black95 text-sm font-500" label="收款类型">
      <a-radio-group v-model="beneficiaryStore.formData.beneficiary_type" @change="(e)=> {
        handleRadio(e, 'beneficiary_type')
      }"  name="radioGroup">
        <a-radio class=" text-base" value="individual">个人</a-radio>
        <a-radio class=" text-sm" value="company">公司</a-radio>
      </a-radio-group>
      </a-form-model-item>
    </div>
    <div class="flex justify-evenly pt-4">
      <a-button @click="handleBackList" type="danger" >
      返回列表
    </a-button>
      <a-button @click="handleNext" type="primary" >
      下一步
    </a-button>
    </div>
    </a-form-model>
  </div>
</template>

<script>
import currencyList from '@/assets/mock_data/currency.js'
import ccCountryList from '@/assets/cc/ccCountry.json'
import { mapActions } from 'vuex'
import { StepOneRules } from './form_rules'
export default {
  created () {
    this.StepOneRules = StepOneRules
    this.$emit('update:current', 0)
  },
  data () {
    return {
      StepOneRules: {},
      currencyList,
      ccCountryList,
      form: {}
    }
  },
  methods: {
    resetForm () {
      this.$refs.beneficiaryAddStepOneForm.resetFields()
    },
    handleBackList () {
      this.$router.push('/user/beneficiary')
    },
    handleInput (e, content) {
      const { value } = e.target
      const _value = value.replace(/[\u4E00-\u9FA5]/g, '')
      this.ChangeFormAction({ content: _value })
    },
    handleRadio (e, content) {
      // this.ChangeFormAction({ content: e.target.value })
    },
    handleSelect (e, content) {
      this.ChangeFormAction({ content: e })
    },
    getCurrencyImg (name) {
      return require(`@/assets/currency/${name}.png`)
    },
    handleNext () {
      this.$refs.beneficiaryAddStepOneForm.validate(
        (valid) => {
          if (valid) {
            this.$router.push('/user/beneficiary/add/step-two')
          } else {
            this.$message.error('请填写正确的信息')
            return false
          }
        }
      )
    },
    ...mapActions('ccBeneficiary', ['ChangeFormAction', 'ResetFormAction'])
  },
  computed: {
    beneficiaryStore () {
      return this.$store.state.ccBeneficiary
    }
  }
}
</script>

<style scoped>

</style>
