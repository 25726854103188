const data = [
  {
    key: '1',
    name: 'CNY',
    currency_nz: 'CNY',
    amount: '0.00',
    currency_cn: '人民币',
    deposit_amount: '0.00 CNY',
    account_balance: '0.00 CNY',
    payment_method: {}
  },
  {
    key: '2',
    name: 'HKD',
    currency_nz: 'HKD',
    amount: '0.00',
    currency_cn: '港币',
    deposit_amount: '0.00 HKD',
    account_balance: '0.00 HKD'
  },
  {
    key: '3',
    name: 'USD',
    currency_nz: 'USD',
    amount: '0.00',
    currency_cn: '美金',
    deposit_amount: '0.00 USD',
    account_balance: '0.00 USD'
  },
  {
    key: '4',
    name: 'EUR',
    currency_nz: 'EUR',
    amount: '0.00',
    currency_cn: '欧元',
    deposit_amount: '0.00 EUR',
    account_balance: '0.00 EUR'
  },
  {
    key: '5',
    name: 'GBP',
    currency_nz: 'GBP',
    amount: '0.00',
    currency_cn: '英镑',
    deposit_amount: '0.00 GBP',
    account_balance: '0.00 GBP'
  },
  {
    key: '6',
    name: 'CAD',
    currency_nz: 'CAD',
    amount: '0.00',
    currency_cn: '加拿大元',
    deposit_amount: '0.00 CAD',
    account_balance: '0.00 CAD'
  },
  {
    key: '7',
    name: 'JPY',
    currency_nz: 'JPY',
    amount: '0.00',
    currency_cn: '日元',
    deposit_amount: '0.00 JPY',
    account_balance: '0.00 JPY'
  },
  {
    key: '8',
    name: 'SGD',
    currency_nz: 'SGD',
    amount: '0.00',
    currency_cn: '新加坡元',
    deposit_amount: '0.00 SGD',
    account_balance: '0.00 SGD'
  },
  {
    key: '9',
    name: 'AUD',
    currency_nz: 'AUD',
    amount: '0.00',
    currency_cn: '澳元',
    deposit_amount: '0.00 AUD',
    account_balance: '0.00 AUD'
  },
  {
    key: '10',
    name: 'NZD',
    currency_nz: 'NZD',
    amount: '0.00',
    currency_cn: '新西兰元',
    deposit_amount: '0.00 NZD',
    account_balance: '0.00 NZD'
  },
  {
    key: '11',
    name: 'CHF',
    currency_nz: 'CHF',
    amount: '0.00',
    currency_cn: '瑞士法郎',
    deposit_amount: '0.00 CHF',
    account_balance: '0.00 CHF'
  },
  {
    key: '12',
    name: 'DKK',
    currency_nz: 'DKK',
    amount: '0.00',
    currency_cn: '丹麦克朗',
    deposit_amount: '0.00 DKK',
    account_balance: '0.00 DKK'
  },
  {
    key: '13',
    name: 'NOK',
    currency_nz: 'NOK',
    amount: '0.00',
    currency_cn: '挪威克朗',
    deposit_amount: '0.00 NOK',
    account_balance: '0.00 NOK'
  },
  {
    key: '14',
    name: 'SEK',
    currency_nz: 'SEK',
    amount: '0.00',
    currency_cn: '瑞典克朗',
    deposit_amount: '0.00 SEK',
    account_balance: '0.00 SEK'
  }
]
export default data
